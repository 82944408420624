import { link } from "fs";

declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;

export const index = function () {
    let elem = document.getElementById('indexPage');
    if (elem){
        initSwIntro();
        initSwTestimonials();
        // initSwMember();

        // scrollEffectAbout();

        effectHandle();
        effectHandle2();

        handleIdxWhatOpt();
        // handleIdxWhatContent();
    }

    let elemAbout = document.getElementById('aboutPage');
    if (elem || elemAbout){
        initSwClients();
        handleIdxWhatContent();
    }
};

const optSwLogo = {
    spaceBetween: 0,
    slidesPerView: 4,
    grid: {
        rows: 2,
    },
    autoplay: {
        delay: 1600,
        disableOnInteraction: false,
    },

    // loop: true,

    pagination: {
        el: ".swiper-pagination",
        clickable: true,

        dynamicBullets: true,
        dynamicMainBullets: 1,
    },
}

// function initSwMember(){
//     const sw = new Swiper('.index-logo-members .swiper', optSwLogo);
//     return sw;
// }
function initSwClients(){
    const sw = new Swiper('.index-clients .swiper', optSwLogo);
    return sw;
}

const optSwIntro = {
    slidesPerView: 1,

    speed: 800,
    loop: true,
    autoplay: {
        delay: 2000,
        disableOnInteraction: false,
    },
}

const optSwTestimonials = {
    effect: "cards",
    grabCursor: true,

    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },

    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
}
function initSwIntro(){
    const sw = new Swiper('.index-banner .swiper', optSwIntro);
}

function initSwTestimonials(){
    const sw = new Swiper('.index-testimonials .swiper', optSwTestimonials);
}

function effectHandle(){
    let ls = <HTMLElement>document.querySelector('.index-banner__link');

    if (ls){
        let eItems = ls.querySelectorAll('a');

        let maxItem = eItems.length - 1;

        let prev = 0;
        let curItem = 0;
        let nextItem = 1;

        if (eItems.length <= 1){
            return;
        }
        eItems[curItem].classList.remove('cp-btn--next');
        eItems[curItem].classList.add('cp-btn--actived');

        eItems[nextItem].classList.remove('cp-btn--actived');
        eItems[nextItem].classList.add('cp-btn--next');

        setInterval(function(){
            eItems[curItem].classList.remove('cp-btn--actived');

            prev = curItem;
            eItems[prev].classList.add('cp-btn--prev');

            setTimeout(function(){
                eItems[prev].classList.add('hideBtn');
            }, 400)

            eItems[nextItem].classList.remove('cp-btn--next');

            if (curItem == maxItem - 1){
                curItem++;
                nextItem = 0;
            }
            else if (curItem == maxItem){
                curItem = 0;
                nextItem = 1;
            }
            else{
                curItem++;
                nextItem++;
            }
            
            eItems[curItem].classList.remove('hideBtn');
            eItems[curItem].classList.add('cp-btn--actived');     
            
            setTimeout(function(){
                eItems[prev].classList.remove('cp-btn--prev');
                
                eItems[nextItem].classList.remove('hideBtn');
                eItems[nextItem].classList.add('cp-btn--next');
            }, 800)
        }, 2800)

        // idxBannerLink.style.height = links[0].clientHeight + "px";
    }
}


function effectHandle2(){
    let ls = <HTMLElement>document.querySelector('.index-achievement-ls');

    if (ls){
        let eItems = ls.querySelectorAll('.item');

        let maxItem = eItems.length - 1;

        let prev = 0;
        let curItem = 0;
        let nextItem = 1;

        if (eItems.length <= 1){
            return;
        }
        eItems[curItem].classList.remove('cp-btn--next');
        eItems[curItem].classList.add('cp-btn--actived');

        eItems[nextItem].classList.remove('cp-btn--actived');
        eItems[nextItem].classList.add('cp-btn--next');

        setInterval(function(){
            eItems[curItem].classList.remove('cp-btn--actived');

            prev = curItem;
            eItems[prev].classList.add('cp-btn--prev');

            setTimeout(function(){
                eItems[prev].classList.add('hideBtn');
            }, 400)

            eItems[nextItem].classList.remove('cp-btn--next');

            if (curItem == maxItem - 1){
                curItem++;
                nextItem = 0;
            }
            else if (curItem == maxItem){
                curItem = 0;
                nextItem = 1;
            }
            else{
                curItem++;
                nextItem++;
            }
            
            eItems[curItem].classList.remove('hideBtn');
            eItems[curItem].classList.add('cp-btn--actived');     
            
            setTimeout(function(){
                eItems[prev].classList.remove('cp-btn--prev');
                
                eItems[nextItem].classList.remove('hideBtn');
                eItems[nextItem].classList.add('cp-btn--next');
            }, 800)
        }, 2800)

        // idxBannerLink.style.height = links[0].clientHeight + "px";
    }
}

function handleIdxWhatOpt(){
    const btnOpts = document.querySelectorAll('.index-what-wrapper .option-item');
    const titleOpts = document.querySelectorAll('.index-what-wrapper .wrapper-name__item');
    const moreOpts = document.querySelectorAll('.index-what-wrapper .wrapper-more .cp-btn-primary');
    const contentOpts = document.querySelectorAll('.index-what-wrapper .wrapper-content-item');


    if (btnOpts.length !== titleOpts.length || btnOpts.length !== contentOpts.length){
        return;
    }

    let defaultActived = 0;
    btnOpts.forEach(function(temp, idxTemp){
        if (temp.classList.contains('is-actived')){
            defaultActived = idxTemp;
            return defaultActived;
        }
    })

    window.delHandle(btnOpts, 'is-actived');
    window.delHandle(titleOpts, 'is-showed');
    window.delHandle(moreOpts, 'is-showed');
    window.delHandle(contentOpts, 'is-showed');
    
    btnOpts[defaultActived].classList.add('is-actived');
    titleOpts[defaultActived].classList.add('is-showed');
    moreOpts[defaultActived].classList.add('is-showed');
    contentOpts[defaultActived].classList.add('is-showed');

    btnOpts.forEach(function(btn:HTMLElement, idxBtn){
        btn.addEventListener('click', function(e){
            e.preventDefault();

            window.delHandle(btnOpts, 'is-actived');
            btn.classList.add('is-actived');

            window.delHandle(titleOpts, 'is-showed');
            titleOpts[idxBtn].classList.add('is-showed');

            window.delHandle(moreOpts, 'is-showed');
            moreOpts[idxBtn].classList.add('is-showed');
            
            window.delHandle(contentOpts, 'is-showed');
            contentOpts[idxBtn].classList.add('is-showed');
        })
    })
}

function handleIdxWhatContent(){
    let ctts = document.querySelectorAll('.index-what-wrapper .wrapper-content-ls .item');
    ctts.forEach(item => {
        item?.addEventListener('click', function(e){
            e.preventDefault();
            item.classList.toggle('is-showed');
        })
    });
}
