declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;
declare const pagination:any;

export const common = function () {
    mouseBoxHandle();
};

function mouseBoxHandle(){
    // This will log the width of the viewport
    if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/)) {
        if (document.querySelector('.news-list')){
            // window.mouseBoxHandle('news-list', '.mouse-handle');
            
            const items = document.querySelectorAll('.news-list .item');
            items.forEach(function(item:HTMLElement){
                item.addEventListener('click', function(e){
                    e.stopPropagation();
                    let more = <HTMLElement>item.querySelector('.cp-btn-second');
                    more.click();
                })
            })
        }
    
        // let boxes = document.querySelectorAll('.mouse-handle');
        if (document.querySelector('.index-casestudy')){
            // window.mouseBoxHandle('news-list', '.mouse-handle');
            
            const items = document.querySelectorAll('.index-casestudy .casestudy-item');
            items.forEach(function(item){
    
                item.addEventListener('click', function(){
                    let more = <HTMLElement>item.querySelector('.cp-btn-second');
                    more.click();
                })
            })
        }
    
        if (document.querySelector('.index-what')){
            // window.mouseBoxHandle('news-list', '.mouse-handle');
            
            const items = document.querySelectorAll('.index-what .wrapper-content-ls .item');
            items.forEach(function(item){
                let more = <HTMLElement>item.querySelector('.cp-btn-second');
                let dtShowed = "";
    
                item.addEventListener('click', function(){
                    dtShowed = more.getAttribute('data-showed');
                    more.setAttribute('data-showed', more.querySelector('span').innerText);
                    more.querySelector('span').innerText = dtShowed;
                })
            })
        }
    } else {
        if (document.querySelector('.news-list')){
            // window.mouseBoxHandle('news-list', '.mouse-handle');
            
            const items = document.querySelectorAll('.news-list .item');
            items.forEach(function(item:HTMLElement){
                window.mouseBoxItemHandle(item, '.cp-btn-second');
                item.addEventListener('click', function(e){
                    e.stopPropagation();
                    let more = <HTMLElement>item.querySelector('.cp-btn-second');
                    more.click();
                })
            })
        }
    
        // let boxes = document.querySelectorAll('.mouse-handle');
        if (document.querySelector('.index-casestudy')){
            // window.mouseBoxHandle('news-list', '.mouse-handle');
            
            const items = document.querySelectorAll('.index-casestudy .casestudy-item');
            items.forEach(function(item){
    
                window.mouseBoxItemHandle(item, '.cp-btn-second');
    
                item.addEventListener('click', function(){
                    let more = <HTMLElement>item.querySelector('.cp-btn-second');
                    more.click();
                })
            })
        }
    
        if (document.querySelector('.index-what')){
            // window.mouseBoxHandle('news-list', '.mouse-handle');
            
            const items = document.querySelectorAll('.index-what .wrapper-content-ls .item');
            items.forEach(function(item){
                window.mouseBoxItemHandle(item, '.cp-btn-second');
                let more = <HTMLElement>item.querySelector('.cp-btn-second');
                let dtShowed = "";
    
                item.addEventListener('click', function(){
                    dtShowed = more.getAttribute('data-showed');
                    more.setAttribute('data-showed', more.querySelector('span').innerText);
                    more.querySelector('span').innerText = dtShowed;
                })
            })
        }
    }
}