import { link } from "fs";

declare const window :any;

export const header = function(){
    let eHeader = <HTMLElement>document.querySelector('.header');
    if(eHeader){
        handleToggle(eHeader);
        handleLogoScroll(eHeader);
    }
}

function handleToggle(elem:HTMLElement){
    var toggle = elem.querySelector('.header-right-toggle');

    toggle?.addEventListener('click', function(e){
        e.preventDefault();

        toggle?.classList.toggle('is-showed');

        if (toggle.classList.contains('is-showed')){
            // window.scrollTo({
            //     top: 0,
            //     left: 0,
            //     behavior: "smooth"				
            // });

            elem.classList.add('showed-navbar');
            document.body.classList.add('hideScroll')
        }
        else{
            document.body.classList.remove('hideScroll');
            elem.classList.remove('showed-navbar');
        }
    })

    let timeoutRs:any;
    window.addEventListener('resize', function(){
        clearTimeout(timeoutRs);
        timeoutRs = setTimeout(function(){
            if (window.screen.width > window.table){
                if (toggle.classList.contains('is-showed')){
                    document.body.classList.remove('hideScroll');
                    toggle.classList.remove('is-showed');
                }
            }
        }, 20)
    })


    // handle menu level 2
    let lsItem = document.querySelectorAll('.header-right-nav>ul>li');
    lsItem.forEach(function(item:HTMLElement){
        let iconShow = item.querySelector('.icon');
        let linkSub = item.querySelector('a[href="javascript:void(0)"]');

        linkSub?.addEventListener('click', function(e){
            item.classList.toggle('is-showed');
        })

        // if (iconShow)Ơ
        iconShow?.addEventListener('click', function(e){
            e.preventDefault();

            item.classList.toggle('is-showed');
        })
    })


    // handle languages
    let langSelected = document.querySelector('.header-right-languages .languages-selected');
    langSelected?.addEventListener('click', function(e){
        e.preventDefault();
        langSelected.classList.toggle('is-showed');
    })
}

function handleLogoScroll(elem:HTMLElement){
    const eLogo = elem.querySelector('.header__logo');

    const logoHeader = <HTMLElement> elem.querySelector('.header__logo>img');
    var srcImg = logoHeader.getAttribute('src') + "";

    var srcImgChange = srcImg.replace('logo-header-full', 'logo-header');
    var eImgAdd = document.createElement('img');

    eImgAdd?.classList.add('logoChange');
    eImgAdd?.setAttribute('src', srcImgChange);

    eLogo.appendChild(eImgAdd);

    let timeoutScroll:any;
    window.addEventListener('scroll', function(e:any){
        clearTimeout(timeoutScroll);
        timeoutScroll = setTimeout(function(){
            if (window.scrollY > 80){
                elem.classList.add('scrolling');
                logoHeader.classList.add('hideImg');
                eImgAdd.classList.add('reverseEfect');
            }
            else if (window.scrollY == 0){
                elem.classList.remove('scrolling');
                logoHeader.classList.remove('hideImg');
                eImgAdd.classList.remove('reverseEfect');
            }
            else{
                // logoHeader.setAttribute('src', srcImg);
            }
        }, 100)
    })
}