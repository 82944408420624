declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;

export const services = function () {
    let elem = document.getElementById('servicesPage');
    if (elem){
        initSwMarketPartners(elem);
    }
};

// const optSwPartners = {
//     slidesPerView: "auto",
//     loop: true,
//     speed: 1600,
//     autoplay: {
//         delay: 0,
//         disableOnInteraction: false,
//     },
// }

const optSwPartners = window.optsw.loopSlide;

function initSwMarketPartners(elem:HTMLElement){
    const sw = new Swiper(elem.querySelector('.services-market-wrapper .swiper'), optSwPartners);
}