declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;

let ghandleClose:any;
const viewHeight = window.innerHeight;
const viewWidth = window.innerWidth;

export const about = function () {
    let elem = document.getElementById('aboutPage');
    if (elem){
        initSwTeams(elem);
        initSwTestimonials(elem);

        handleMilestone(elem);
        initSwPartners();

        handleUrl();
    }
};


const optSwTeams = {
    slidesPerView: "auto",
    // loop: true,
    speed: 800,
    autoplay: {
        delay: 2200,
        disableOnInteraction: false,
    },
}

const optSwTestimonials = {
    slidesPerView: "auto",
    loop: true,
    centeredSlides: true,
    spaceBetween: 30,

    // speed: 800,
    // autoplay: {
    //     delay: 1200,
    //     disableOnInteraction: false,
    // },

    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
}

const optSwMilestoneMain = {
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 30,

    speed: 800,

    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
}
const optSwMilestoneThumbs = {
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 800,
}

// const optSwTeams = window.optsw.loopSlide;

function initSwMilestone_main(elem:HTMLElement){
    if (elem.querySelector('.about-milestone-wrapper .about-milestone__sw-main .swiper')){
        const sw = new Swiper(elem.querySelector('.about-milestone-wrapper .about-milestone__sw-main .swiper'), optSwMilestoneMain);
        return sw;
    }
    return false;
}
function initSwMilestone_thumbs(elem:HTMLElement){
    if (elem.querySelector('.about-milestone-wrapper .about-milestone__sw-thumbnails .swiper')){
        const sw = new Swiper(elem.querySelector('.about-milestone-wrapper .about-milestone__sw-thumbnails .swiper'), optSwMilestoneThumbs);
        return sw;
    }
    return false;
}

function handleMilestone(elem:HTMLElement){
    const swmain = initSwMilestone_main(elem);
    const swthumbs = initSwMilestone_thumbs(elem);

    if (swmain === false || swthumbs === false){
        return false;
    }

    window.sliderThumbs(swmain, swthumbs);
    return true;
}

function initSwTeams(elem:HTMLElement){
    if(elem.querySelector('.about-team-wrapper .swiper')){
        const sw = new Swiper(elem.querySelector('.about-team-wrapper .swiper'), optSwTeams);
        return sw;
    }
    return false;
}

function initSwTestimonials(elem:HTMLElement){
    if (elem.querySelector('.about-testimonials-wrapper .swiper')){
        const sw = new Swiper(elem.querySelector('.about-testimonials-wrapper .swiper'), optSwTestimonials);
        return sw;
    }
    return false;
}

const optSwLogo = {
    spaceBetween: 0,
    slidesPerView: "auto",
    speed: 800,
    autoplay: {
        delay: 1200,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".about-partners .swiper-button-next",
        prevEl: ".about-partners .swiper-button-prev",
    },
}

function initSwPartners(){
    if (document.querySelector('.about-partners .swiper')){
        const sw = new Swiper('.about-partners .swiper', optSwLogo);
        return sw;
    }
    return false;
}



function handlePopuped(element:HTMLElement, list:any, cancel:boolean = false){
    let detail = <HTMLElement> element;
    if (detail){
        // element.classList.add('showed');
        element.classList.add('is-animated');
        document.body.classList.add('hideScroll');
        
        const backToAll = detail.querySelector('.backtoall');
        const close = detail.querySelector('.close');

        element.style.userSelect = "";
        element.style.pointerEvents = "";

        function handleClose(e:any = null){
            const closeHref = backToAll.getAttribute('href') ? backToAll.getAttribute('href') : "";

            backToAll.removeEventListener('click', handleClose);
            close.removeEventListener('click', handleClose);
            document.removeEventListener('keyup', escHandle);

            element.classList.remove('showed');
            element.classList.remove('is-animated');

            document.body.classList.remove('hideScroll');

            if (closeHref && closeHref !== "javascript:void(0)"){
                window.location.href = close.getAttribute('href');
            }

            list.forEach(function(temp:HTMLElement){
                temp.style.userSelect = "";
                temp.style.pointerEvents = "";
            })
        }

        ghandleClose = handleClose;
        function escHandle(event:any){
            if (event.key === "Escape") {
              handleClose();
            }
        }

        if (cancel=== false){            
            document.addEventListener('keyup', escHandle);
    
            backToAll.addEventListener('click',handleClose);
            close.addEventListener('click', handleClose);
        }
        else{
            handleClose();
        }
    }
}

function handleUrl(){
    const lsOpt = document.querySelectorAll('.about-team .member-popup');

    lsOpt.forEach(function(item:HTMLElement, id){
        if (item.classList.contains('showed')){
            lsOpt.forEach(function(temp:HTMLElement){
                temp.style.userSelect = "none";
                temp.style.pointerEvents = "none";
            })
            
            item.scrollIntoView();
            handlePopuped(item, lsOpt);
        }
    })
}
