// import axios from "axios";
declare const $: any;
declare const window: any;
const viewHeight = window.innerHeight;

// Common function
const fetchData = () => {
	if (document.getElementById('casestudiesPage')){
		const handleLoadmoreCaseStudy = function(){
			const wrapper = <HTMLElement> document.querySelector('.index-casestudy-wrapper');
			const loadmore = wrapper?.querySelector('.viewmore .cp-btn-primary');
	
			if (loadmore){
				let checkingApi = false;

				function handleClickNewsAPI(e:any){
					e.preventDefault();
					if(checkingApi === false){
						// call API
						const data = {
							id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
							page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
						}
						setTimeout(function(){
							$.ajax({
								url: '/api/casestudy-viewmore',
								type: 'GET',
								dataType: 'html',
								data: data,
								success: function(response:any) {
									// remove event
									loadmore.classList.remove('loader');
									loadmore.removeEventListener('click', handleClickNewsAPI);

									loadmore.parentElement.parentElement.remove();

									// add data
									const ul = wrapper.querySelector('ul');
									ul.innerHTML += response;

					
									// repeat call api 
									handleLoadmoreCaseStudy();
								},
								error: function(xhr:any, status:any, error:any) {
									console.log(error);
									checkingApi = false;
									loadmore.classList.remove('loader');
								}
							});
						}, 800);
						// loadmore.remove();
				
						checkingApi = true;
						loadmore.classList.add('loader');
					}
				}
				loadmore.addEventListener('click', handleClickNewsAPI)
			}
		}

		handleLoadmoreCaseStudy();
	}


	if (document.getElementById('newsPage')){
		const handleLoadmoreNews = function(){
			const wrapper = <HTMLElement> document.querySelector('.news-list');
			const loadmore = wrapper?.querySelector('.viewmore .cp-btn-primary');
	
			if (loadmore){
				let checkingApi = false;

				function handleClickNewsAPI(e:any){
					e.preventDefault();
					if(checkingApi === false){
						// call API
						const data = {
							id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
							page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
						}
						setTimeout(function(){
							$.ajax({
								url: '/api/news-viewmore',
								type: 'GET',
								dataType: 'html',
								data: data,
								success: function(response:any) {
									// remove event
									loadmore.classList.remove('loader');
									loadmore.removeEventListener('click', handleClickNewsAPI);

									loadmore.remove();

									// add data
									const ul = wrapper.querySelector('ul');
									ul.innerHTML += response;

									const items = document.querySelectorAll('.news-list .item');
									items.forEach(function(item:HTMLElement){
										window.mouseBoxItemHandle(item, '.cp-btn-second');
							
										item.addEventListener('click', function(e){
											e.stopPropagation();
											
											let more = <HTMLElement>item.querySelector('.cp-btn-second');
											more.click();
										})
									})

									// repeat call api 
									handleLoadmoreNews();
								},
								error: function(xhr:any, status:any, error:any) {
									console.log(error);
									checkingApi = false;
									loadmore.classList.remove('loader');
								}
							});
						}, 1200);
						// loadmore.remove();
				
						checkingApi = true;
						loadmore.classList.add('loader');
					}
				}
				loadmore.addEventListener('click', handleClickNewsAPI)
			}
		}

		handleLoadmoreNews();
	}


	// if (document.getElementById('casestudiesPage')){
	// 	const handleLoadmoreNews = function(){
	// 		const wrapper = <HTMLElement> document.querySelector('.index-casestudy-wrapper');
	// 		const loadmore = wrapper?.querySelector('.viewmore');
	
	// 		if (loadmore){
	// 			let checkingApi = false;

	// 			// function apiLoadmore(){
	// 			// 	const top = loadmore ? loadmore.getBoundingClientRect().top : 0; 
	// 			// 	const rectTop = top + 60;
	
	// 			// 	if (rectTop <= viewHeight){
	// 			// 		if(checkingApi === false){
	// 			// 			// call API
	// 			// 			const data = {
	// 			// 				id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
	// 			// 				page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
	// 			// 			}
		
	// 			// 			$.ajax({
	// 			// 				url: '/api/newsloadmore',
	// 			// 				type: 'GET',
	// 			// 				dataType: 'html',
	// 			// 				data: data,
	// 			// 				success: function(response:any) {
	// 			// 					loadmore.remove();

	// 			// 					const ul = wrapper.querySelector('ul');
	// 			// 					ul.innerHTML += response;
									
	// 			// 					handleLoadmoreNews();
	// 			// 				},
	// 			// 				error: function(xhr:any, status:any, error:any) {
	// 			// 					console.log(error);
	// 			// 					checkingApi = false;
	// 			// 				}
	// 			// 			});
	// 			// 		}

	// 			// 		checkingApi = true;
	// 			// 		window.removeEventListener('scroll', apiLoadmore);
	// 			// 	}
	// 			// }
	// 			// apiLoadmore();
	// 			// window.addEventListener('scroll', apiLoadmore);

	// 			function handleClickNewsAPI(e:any){
	// 				e.preventDefault();
	// 				if(checkingApi === false){
	// 					// call API
	// 					const data = {
	// 						id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
	// 						page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
	// 					}
				
	// 					$.ajax({
	// 						url: '/api/casestudy-viewmore',
	// 						type: 'GET',
	// 						dataType: 'html',
	// 						data: data,
	// 						success: function(response:any) {
	// 							// remove event
	// 							loadmore.classList.remove('loader');
	// 							loadmore.removeEventListener('click', handleClickNewsAPI);

	// 							loadmore.remove();

	// 							// add data
	// 							const ul = wrapper.querySelector('ul');
	// 							ul.innerHTML += response;

				
	// 							// repeat call api 
	// 							handleLoadmoreNews();
	// 						},
	// 						error: function(xhr:any, status:any, error:any) {
	// 							console.log(error);
	// 							checkingApi = false;
	// 							loadmore.classList.remove('loader');
	// 						}
	// 					});
	// 					// loadmore.remove();
				
	// 					checkingApi = true;
	// 					loadmore.classList.add('loader');
	// 				}
	// 			}
	// 			loadmore.addEventListener('click', handleClickNewsAPI)
	// 		}
	// 	}

	// 	handleLoadmoreNews();
	// }
};

// const formData = new FormData();
// formData.append("FirstName", "Sơn");
// formData.append("LastName", "Vũ");
// axios.post("/user", formData).then(function (response) {
// 	console.log(response);
// });

// axios.get("./api/test.json")
// 	.then((response) => {
// 		return response;
// 	})
// 	.finally(() => {});

export default {
	fetchData: fetchData,
}