declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;

export const contact = function () {
    let elem = document.getElementById('contactPage');
    if (elem){
        handleFAQ(elem);handleOffices(elem);
    }
};


function handleFAQ(elem:HTMLElement){
    let faqs = elem.querySelectorAll('.contact-faq-ls .item');
    faqs.forEach(faq => {
        let name = faq.querySelector('.item__name');
        name?.addEventListener('click', function(e){
            e.preventDefault();
            faq.classList.toggle('is-showed');
        })
    });
}

function handleOffices(elem:HTMLElement){
    let items = elem.querySelectorAll('.contact-offices-ls .item');
    let maps = elem.querySelectorAll('.contact-offices__maps');

    if (items.length !== maps.length){
        console.error('Offices is not equal!');
        return false;
    }
    let defaultActived = 0;
    items.forEach(function(temp, idxTemp){
        if (temp.classList.contains('is-actived')){
            defaultActived = idxTemp;
            return defaultActived;
        }
    })

    window.delHandle(items, 'is-actived');
    window.delHandle(maps, 'is-showed');

    items[defaultActived].classList.add('is-actived');
    maps[defaultActived].classList.add('is-showed');

    items.forEach(function(item:HTMLElement, idxItem){
        let link = item.querySelector('.item__addr>a');
        link.addEventListener('click', function(e){
            e.preventDefault();

            window.delHandle(items, 'is-actived');
            item.classList.add('is-actived');

            window.delHandle(maps, 'is-showed');
            maps[idxItem].classList.add('is-showed');
        })
    })
}