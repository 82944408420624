declare const window: any;
declare const $: any;
const _window = window;

export const _base = function () {
	window.cp = 1024; // computer min 1024
	window.tablet = 768; // table min 768

	// moble < 768
	window.delHandle = function (list: any, str: any) {
		if (typeof str == "string") {
			list.forEach(function (item: HTMLElement) {
				item.classList.remove(str);
			});
		}
	};

	window.getQueryString = function (params: any) {
		var str = Object.keys(params)
			.map(function (key) {
				return key + "=" + params[key];
			})
			.join("&");
		return str;
	};

	// mouse in seemore box
	window.mouseBoxHandle = function (lsName: string, queryMouseHandle: string) {
		// var boxes = document.querySelectorAll(listItem);
		var mousePos:any;
		var checked = false;

		function handleMouseMove(event:any){
			var dot, eventDoc, doc, body, pageX, pageY;
			event = event || window.event; // IE-ism
			// If pageX/Y aren't available and clientX/Y are,
			// calculate pageX/Y - logic taken from jQuery.
			// (This is to support old IE)
			if (event.pageX == null && event.clientX != null) {
				eventDoc = (event.target && event.target.ownerDocument) || document;
				doc = eventDoc.documentElement;
				body = eventDoc.body;
	
				event.pageX = event.clientX +
				  (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
				  (doc && doc.clientLeft || body && body.clientLeft || 0);
				event.pageY = event.clientY +
				  (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
				  (doc && doc.clientTop  || body && body.clientTop  || 0 );
			}
	
			mousePos = {
				x: event.clientX,
				y: event.clientY
			};

			let target = event.target;
			// console.log(boxes);
			if (target && target.parentElement && target.parentElement.classList.contains(lsName)){
				checked = true;	
			}
			else{
				do{
					target = target.parentElement;
	
					if (target && target.parentElement == null){
						checked = false;
						break;
					}
					
					if (target && target.parentElement && target.parentElement.classList.contains(lsName)){
						checked = true;
						return;
					}
				}while(target.parentElement !== null)
				checked = false;
			}
		}
		
		function getMousePosition() {
			var pos = mousePos;
			if (!pos) {
				// We haven't seen any movement yet
			}
			else {
				// Use pos.x and pos.y
				var divMouse = <HTMLElement>document.querySelector(queryMouseHandle);
				divMouse.style.top = mousePos.y + "px";
				divMouse.style.left = mousePos.x + "px";
				if (checked){
					divMouse.classList.add('visible');
				}else{
					divMouse.classList.remove('visible');
				}
			}
		}
		document.onmousemove = handleMouseMove;
		setInterval(getMousePosition); 
	};
	
	window.mouseBoxItemHandle = function (elementItem: HTMLElement, queryMouseHandle: string) {
		// var boxes = document.querySelectorAll(listItem);
		var mousePos:any;
		var checked = false;

		function handleMouseMove(event:any){
			var dot, eventDoc, doc, body, pageX, pageY;
			event = event || window.event; // IE-ism
			// If pageX/Y aren't available and clientX/Y are,
			// calculate pageX/Y - logic taken from jQuery.
			// (This is to support old IE)
			if (event.pageX == null && event.clientX != null) {
				eventDoc = (event.target && event.target.ownerDocument) || document;
				doc = eventDoc.documentElement;
				body = eventDoc.body;
	
				event.pageX = event.clientX +
				  (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
				  (doc && doc.clientLeft || body && body.clientLeft || 0);
				event.pageY = event.clientY +
				  (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
				  (doc && doc.clientTop  || body && body.clientTop  || 0 );
			}
	
			mousePos = {
				x: event.clientX,
				y: event.clientY
			};

			let target = event.target;
			checked = true;	

			// console.log(boxes);
			// if (target && target.parentElement && target.parentElement.classList.contains('')){
			// 	checked = true;	
			// }
			// else{
			// 	do{
			// 		target = target.parentElement;
	
			// 		if (target && target.parentElement == null){
			// 			checked = false;
			// 			break;
			// 		}
					
			// 		if (target && target.parentElement && target.parentElement.classList.contains('')){
			// 			checked = true;
			// 			return;
			// 		}
			// 	}while(target.parentElement !== null)
			// 	checked = false;
			// }
		}

		function handleMouseOut(event:any){
			checked = false;	
		}

		
		
		function getMousePosition() {
			var pos = mousePos;
			if (!pos) {
				// We haven't seen any movement yet
			}
			else {
				// Use pos.x and pos.y
				var divMouse = <HTMLElement>elementItem.querySelector(queryMouseHandle);
				divMouse.style.top = mousePos.y + "px";
				divMouse.style.left = mousePos.x + "px";
				if (checked){
					divMouse.classList.add('visible');
				}else{
					divMouse.classList.remove('visible');
				}
			}
		}

		elementItem.onmousemove = handleMouseMove;
		elementItem.onmouseout = handleMouseOut;
		setInterval(getMousePosition); 
	};

	window.copyMaker = function(copyElement:HTMLElement, callback:Function){
		let textCopy = copyElement.getAttribute('href');
		if (!textCopy || textCopy === "#"){
			textCopy = window.location.href;
		}
		const textElementSuccess = copyElement.getAttribute("data-ss") + "";
		const textElementError = copyElement.getAttribute("data-er") + "";

		try{	
			copyElement.removeAttribute('dt-ss');
			copyElement.removeAttribute('dt-er');

			copyElement.addEventListener("click", function(e){
				e.preventDefault();
				const input = document.createElement("input");
				input.value = textCopy;
				input.setAttribute('type', 'text');
				input.style.height = "0";
				input.style.opacity = "0";
				let body = document.querySelector('body');
				body.appendChild(input);

				input.select();

				document.execCommand("copy");

				input.remove();

				// window.getSelection().selectAllChildren(input);
				// input.execCommand("copy");

				copyElement.setAttribute("tooltip", textElementSuccess);

				callback('success', textElementSuccess);
			});

			// copyElement.addEventListener("mouseover", function(e){
			// 	copyElement.setAttribute("tooltip", textElement);
			// });
		}
		catch{
			callback('error', textElementError);
		}
	}

	window.addToastMsg = function(status:string, message:string){
		let toastMsg = <HTMLElement>document.querySelector('.toast-msg');
		if (!toastMsg){
			let eToast = document.createElement('ul');
			eToast.classList.add('toast-msg');

			toastMsg = eToast;

			document.body.appendChild(toastMsg);
		}

		let item = document.createElement('li');
		
		item.classList.add(status);

		item.innerHTML = `
			<p>${message}</p>
		`;
		let timeMsg = 3000;
		let timeDurationMsg = 600;

		const iChild = 5;

		item.style.transitionDuration = (timeDurationMsg / 1000) + "s";

		item.addEventListener('click', function(){
			let promise = new Promise(function(res, rej){
				item.classList.remove('active');
				res(item);
			})
			promise.then(function(value){
				setTimeout(function(){
					item.remove();
				}, timeDurationMsg)
			})
		})

		// time message
		let timeout = new Promise(function(resole, reject){
			try{
				if (!item){
					throw 'Not item message!';
				}
				setTimeout(function(){
					item.classList.add('active');
					resole(item);
				}, 0)
			}
			catch(err){
				reject(err)
			}
		})

		timeout.then(function(item:HTMLElement){
			return new Promise(function(resole, reject){
				setTimeout(function(){
					item.classList.remove('active');
					resole(item);
				}, timeMsg)
			})
		}).then(function(value:HTMLElement){
			setTimeout(function(){
				value.remove();

				// remove Toast list
				if (toastMsg.children.length === 0){
					toastMsg.remove();
				}
			}, timeDurationMsg)
		})
		.catch(function(error){
			console.error(`${error}`)
		})

		if (toastMsg.children.length + 1 > iChild ){
			toastMsg.children[0].remove();
		}

		toastMsg.appendChild(item);
	}

	window.targetHandle = function(target:any = document,checkCondition:Function, callBack:Function){
		target.addEventListener('click', function(e:any){
			let target = e.target;

			if (checkCondition(target) === true){
				return;
			}

			target = target.parentElement;
			do{
				if (checkCondition(target) === true){
				return;
				}
				target = target.parentElement;
			}while(target.parentElement);

			callBack();
		})
	}

	function closeHandle(popup:any, callBack:Function = function(){}){
		popup.classList.remove('is-actived');

		callBack(popup);
	}

	window.popupHandle = function(target:any, callBackClose:Function = function(){return""}){
		try{
			if (typeof target === 'string'){
				target = document.querySelector(`${target}`);
			}

			function clickHandleTarget(e:any){
				e.preventDefault();
				popup.classList.add('is-actived');
			}
			
			target.removeEventListener('click', clickHandleTarget);
			target.addEventListener('click', clickHandleTarget);

			let popup = document.querySelector(`[data-popup-section="${target.getAttribute('data-popup')}"]`);
			if (!popup){
				throw `Not found popup: ${target.getAttribute('data-popup')}`;
			}

			if (popup.classList.contains('initialized')){
				return;
			}

			let closes = popup.querySelectorAll('.close');

			function evtClose(e:any){
				e.preventDefault();
				closeHandle(popup, callBackClose);
			}

			closes.forEach(function(close){
				close.addEventListener('click', evtClose);
			})
			
			let cancels = popup.querySelectorAll('.cancel');
			cancels.forEach(function(cancel:HTMLElement){
				cancel.addEventListener('click', function(e:any){
					e.preventDefault();
					closeHandle(popup);
				})
			})

			_window.targetHandle(popup, function(e:HTMLElement){
				if (e.classList.contains('wrapper')){
					return true;
				}
				return false;
			}, function(){
				closeHandle(popup, callBackClose);
			})

			let modal = popup.querySelector('.modal');
			modal.addEventListener('click', function(e){
				e.stopPropagation();
			})

			popup.classList.add("initialized");
		}
		catch(err){
			console.error(err);
		}
	}

	window.initPopupHandle = function(target:any, callBackClose:Function = function(){return""}){
		try{
			let popup = target;
			if (typeof target === "string"){
				popup = document.querySelector(target);
			}
			if (!popup){
				throw `Not found popup: ${target.getAttribute('data-popup')}`;
			}

			if (popup.classList.contains('initialized')){
				popup.classList.add('opened');
				return;
			}

			let close = popup.querySelector('.close');
			if (close){
				close.addEventListener('click', function(e:any){
					e.preventDefault();
					closeHandle(popup, callBackClose);
				});
			}
			
			let cancel = popup.querySelector('.cancel');
			if (cancel){
				cancel.querySelector('a').addEventListener('click', function(e:any){
					e.preventDefault();
					closeHandle(popup, callBackClose);
				})
			}

			_window.targetHandle(popup, function(e:HTMLElement){
				if (e.classList.contains('wrapper')){
					return true;
				}
				return false;
			}, function(){
				closeHandle(popup, callBackClose);
			})
			
			// target.addEventListener('click', function(e:any){
			// 	e.preventDefault();
			// })
			
			popup.classList.add('opened');
			popup.classList.add("initialized");
		}
		catch(err){
			console.error(err);
		}
	}

	window.backToTop = (target:HTMLElement, strQuery: string) => {
		const backtotop = <HTMLElement> document.querySelector(strQuery);
		if (backtotop && target){
			
			const handleScroll = () => {
				let top = target.getBoundingClientRect().top;
				if (top <= window.innerHeight + 200){
					backtotop.classList.add("showed");
					return true;
				}

				backtotop.classList.remove('showed');
				return false;
			}

			handleScroll();
			
			let timeout:any;
			window.addEventListener('scroll', () => {
				clearTimeout(timeout);
				timeout = setTimeout(handleScroll, 200);
			})

			const handleClick = (e:any) =>{
				e.preventDefault();
	
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth"				
				});
			}
			backtotop.onclick = handleClick;
		}
	}

	window.optsw = {
		"loopSlide": {
			slidesPerView: "auto",
			loop: true,
			speed: 1600,
			autoplay: {
				delay: 0,
				disableOnInteraction: false,
			},
		}
	}

	window.sliderThumbs = function(swiperSlider:any, swiperThumbs:any, callback:Function = function(){}){
		if (swiperSlider.slides.length !== swiperThumbs.slides.length){
			console.error('Insufficient slides!');
			return;
		}
	
		const defaultSlide = 0;
	
		swiperSlider.slideTo(defaultSlide);
		swiperThumbs.slideTo(defaultSlide);
		callback();
	
		swiperSlider.on('slideChange', function () {
			let activeIdx = swiperSlider.activeIndex;
			swiperThumbs.slideTo(activeIdx);
			callback();
		});
		
		swiperThumbs.on('slideChange', function () {
			let activeIdx = swiperThumbs.activeIndex;
			swiperSlider.slideTo(activeIdx);
			callback();
		});
	}
};
