declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;
declare const pagination:any;

export const news = function () {
    let elem = document.getElementById('newsPage');
    if (elem){
    }
};

// function handleNewsItem(){
//     const lsNews = document.querySelectorAll('.news-list .item');

//     lsNews.forEach(function(item){
//         let more = <HTMLElement> item.querySelector('.item-content-more');
//         item.addEventListener('mouseover', function(e){
//             console.log(e);
//             more.style.display = "block";
//         })
//     })
// }


// function initPage(){
//     return new window.pagination({
//         selector: '.news-1 .pagination',
//         items: '.news-1 .wrapper-ls .item',
//         scroll: {
//             id: 'scrollnews'
//         },
//         page: 6,
//     })
// }